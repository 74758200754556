.transferCallModal[data-v-dbeee7db] .ui.form .field > label,
.transferCallModal[data-v-dbeee7db] .ui.form .field > .ui.input {
  min-width: auto !important;
}
.transferCallModal[data-v-dbeee7db] .ui.form .field > label {
  margin-right: 20px !important;
}
.transferCallModal[data-v-dbeee7db] .ui.form .fields {
  justify-content: center;
  margin: 1.5em 0 0.5em;
}
.transferCallModal[data-v-dbeee7db] .ui.message {
  margin-top: 1.5em;
}
