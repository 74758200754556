[data-v-61bfcfef] .validation-grid {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
[data-v-61bfcfef] .validation-grid .validation-file {
    margin: 1.5em;
}
[data-v-61bfcfef] .validation-grid .validation-template,[data-v-61bfcfef] .validation-grid .summary-errors {
    margin-top: 1em;
}
[data-v-61bfcfef] .validation-grid .validation-template button,[data-v-61bfcfef] .validation-grid .summary-errors button {
      background-color: rgba(0, 0, 0, 0);
      border: solid !important;
      margin-bottom: 1em;
}
[data-v-61bfcfef] .validation-grid .validation-template button:hover,[data-v-61bfcfef] .validation-grid .summary-errors button:hover {
      background-color: #f6882e;
      color: white !important;
}
[data-v-61bfcfef] .validation-grid .validation-template p,[data-v-61bfcfef] .validation-grid .summary-errors p {
      margin: 0.5em;
      font-weight: 200;
}
[data-v-61bfcfef] .validation-grid .validation-status {
    margin-top: 0.5em;
    margin-bottom: 0;
}
[data-v-61bfcfef] .validation-grid .filenames-label {
    font-weight: bold;
}
[data-v-61bfcfef] .validation-grid .progress {
    margin-top: 1em !important;
    width: 50em;
}
[data-v-61bfcfef] .validation-grid .SummaryItems {
    display: flex;
    margin-top: 2em;
}
[data-v-61bfcfef] .validation-grid .SummaryItems .summary-circle p {
      display: flex;
      justify-content: center;
      margin: 0;
}
[data-v-61bfcfef] .validation-grid .SummaryItems .summary-circle div {
      padding: 10px;
      margin: 20px;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      left: 0;
      top: 0;
}
[data-v-61bfcfef] .validation-grid .SummaryItems .summary-circle div p {
        color: white;
        align-items: center;
        text-align: center;
        font-weight: 700;
        margin-top: 30px !important;
        font-size: 15px;
}
[data-v-61bfcfef] .validation-grid table {
    margin-bottom: 1em !important;
}
[data-v-61bfcfef] .summary-errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
}
[data-v-61bfcfef] .summary-errors button {
    margin: 1.5em !important;
}
[data-v-61bfcfef] .summary-errors p {
    font-weight: 600;
}
.summary-description[data-v-61bfcfef] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
}
.validation-msg[data-v-61bfcfef] {
  font-weight: 600;
  margin: 1em;
}
.description-container[data-v-61bfcfef] {
  display: flex;
  flex-direction: column;
}
.description-counter[data-v-61bfcfef] {
  margin-top: -15px;
  align-self: flex-end;
}
.ProgressBar[data-v-61bfcfef] {
  margin: 0 auto;
  padding: 2em 0 3em;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.ProgressBar-step[data-v-61bfcfef] {
  text-align: center;
  position: relative;
  width: 100%;
}
.ProgressBar-step p[data-v-61bfcfef] {
    display: block;
    font-weight: bold;
    position: absolute;
    padding-top: 0.5em;
    width: 100%;
    transition: all 0.25s ease-out;
}
.ProgressBar-step[data-v-61bfcfef]:before, .ProgressBar-step[data-v-61bfcfef]:after {
    content: "";
    height: 0.5em;
    background-color: #aaa;
    position: absolute;
    z-index: 0;
    width: 100%;
    left: -50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: all 0.25s ease-out;
}
.ProgressBar-step[data-v-61bfcfef]:first-child:before, .ProgressBar-step[data-v-61bfcfef]:first-child:after {
    display: none;
}
.icon-check[data-v-61bfcfef]  {
  max-width: 100%;
  z-index: 10;
  position: relative;
  transition: all 0.25s ease-out;
}
.icon-check-fg[data-v-61bfcfef] ,
.icon-check-bg[data-v-61bfcfef]  {
  position: absolute !important;
  z-index: 10;
  -webkit-transform: translateX(-50%) translateY(-55%) !important;
          transform: translateX(-50%) translateY(-55%) !important;
}
.icon-check-bg[data-v-61bfcfef]  {
  color: white;
}
.validation-actions[data-v-61bfcfef] {
  margin: 1.5em 0 1em;
  display: flex;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
}
.validation-actions button > span[data-v-61bfcfef] {
    margin-left: 5px;
}
.import-options[data-v-61bfcfef] {
  display: flex;
  justify-content: center;
}
.campaign-selector .campaign-id[data-v-61bfcfef] {
  color: #999;
}
