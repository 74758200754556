.data-tab-buttons[data-v-176f91fd] {
  background: none !important;
  margin-top: 3em !important;
  border: none !important;
  box-shadow: none !important;
}
@media screen and (max-width: 1500px) {
.data-tab-buttons[data-v-176f91fd] {
    margin-left: 0 !important;
}
}
