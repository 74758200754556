.paginationContainer[data-v-26e251f3] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.paginationContainer .paginationContainerInner[data-v-26e251f3] {
    position: relative;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: center;
            transform-origin: center;
}
.paginationContainer .pagination[data-v-26e251f3] {
    float: none;
    -webkit-transform: none !important;
            transform: none !important;
}
.paginationContainer .loading.icon[data-v-26e251f3] {
    position: absolute;
    top: 25%;
    right: -40px;
    font-size: 1.3em;
}
