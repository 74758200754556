.question.no-border[data-v-8d7f6f76] {
  border: none;
  box-shadow: none;
  padding: 0;
}
.question label[data-v-8d7f6f76] {
  display: inline-block !important;
}
.question .actions[data-v-8d7f6f76] {
  float: right;
}
.question .actions .icon[data-v-8d7f6f76] {
    opacity: 0.5;
}
.question .actions .close.icon[data-v-8d7f6f76] {
    margin-left: 2em;
    margin-right: 0;
}
.question .actions .disabled.icon[data-v-8d7f6f76] {
    opacity: 0.25 !important;
    cursor: default;
}
.question .ui.input[data-v-8d7f6f76]:not(.inline) {
  width: 100% !important;
}
.question .field[data-v-8d7f6f76] {
  margin-bottom: 0.8em !important;
}
.question .field[data-v-8d7f6f76]:last-of-type {
  margin-bottom: 0 !important;
}
.question .options[data-v-8d7f6f76] {
  margin-bottom: 0;
}
.question .options > label[data-v-8d7f6f76] {
    display: block;
    font-weight: bold;
    font-size: 0.9em;
    color: #666;
    margin-top: -0.7em;
}
.question .options .right.checkbox[data-v-8d7f6f76] {
    margin-left: 0.8em;
}
.question .options > div[data-v-8d7f6f76] {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    padding: 0.67857143em 1em;
    vertical-align: middle;
    cursor: pointer;
    display: inline-block;
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.question .options .add-option[data-v-8d7f6f76] {
    margin-left: 0.3em;
}
