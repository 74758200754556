.visibilitySelector[data-v-ca2e265d] .fields {
  margin: 0 !important;
  margin-bottom: 1.3em !important;
}
.visibilitySelector[data-v-ca2e265d] .field:first-of-type {
  margin: 0 5px 0 0 !important;
  padding: 0 !important;
}
.visibilitySelector[data-v-ca2e265d] .visibility {
  width: 160px;
}
.visibilitySelector[data-v-ca2e265d] .visibilityIds {
  width: 325px;
}
