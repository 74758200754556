.sequence-detail[data-v-a4f1b855] .detail-top-buttons {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: none !important;
}
.sequence-detail[data-v-a4f1b855] .detail-top-buttons .header {
    margin: 0 1em 0 0.5em !important;
}
.sequence-detail[data-v-a4f1b855] .detail-top-buttons .spacer {
    flex-grow: 1;
}
.sequence-detail[data-v-a4f1b855] .list-grid {
  padding: 1em 1em 2em 1em !important;
}
