.dial-session.form[data-v-65e203cb] .tabular.menu .item {
  border: none;
}
.dial-session.form[data-v-65e203cb] .tabular.menu .item.active {
    border-bottom: solid #000044 2.5px;
}
.dial-session.form[data-v-65e203cb] .tabular.menu .item:after {
    content: none;
}
.dial-session.form[data-v-65e203cb] .tab {
  border: none !important;
  box-shadow: none !important;
  padding-top: 1.5rem;
}
.dial-session.form[data-v-65e203cb] .tab .accordion .title .field {
    display: inline-block;
    margin-bottom: 0;
}
.dial-session.form[data-v-65e203cb] .tab .accordion .title .icon {
    display: inline-block;
    float: right;
}
.dial-session.form[data-v-65e203cb] .tab .phone-type.draggable-container.phone-type.draggable-container {
    height: 43px;
    max-height: 43px;
}
.dial-session.form[data-v-65e203cb] .tab .phone-type.draggable-container.phone-type.draggable-container > *:active {
      margin-top: -22px;
}
.dial-session.form[data-v-65e203cb] .tab .limit-call-tags-toggle {
    align-self: flex-start;
    margin-top: 5px;
    min-height: 35px;
}
