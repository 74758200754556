.taskList[data-v-3ea994db] .actions {
  margin-bottom: 1.5em;
}
.taskList[data-v-3ea994db] .actions .item-adder,
  .taskList[data-v-3ea994db] .actions .search.input {
    margin-right: 1em;
}
.taskList[data-v-3ea994db] .message {
  word-break: break-all;
}
