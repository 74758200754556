.options-form[data-v-6f62179f] .option {
  display: flex;
}
.options-form[data-v-6f62179f] .option .left {
    flex: 1 1;
}
.options-form[data-v-6f62179f] .option .right {
    display: flex;
    flex: 0 1;
    margin-left: 1em;
}
.options-form[data-v-6f62179f] .option .close.icon {
    margin-left: 4px;
    margin-right: -4px;
}
.options-form[data-v-6f62179f] .option .close.icon,
  .options-form[data-v-6f62179f] .option .actions {
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.87);
    transition: opacity 0.1s ease;
}
.options-form[data-v-6f62179f] .option .actions:hover {
    opacity: 1;
}
.options-form[data-v-6f62179f] .option .ui.input {
    width: 100%;
}
.options-form[data-v-6f62179f] .option .ui.input input {
      border: none;
      padding: 0;
}
