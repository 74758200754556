.email-template-form[data-v-2bc5450c] .title {
  display: flex;
  justify-content: space-between;
}
.email-template-form[data-v-2bc5450c] .title h2 {
    margin: 0;
}
.email-template-form[data-v-2bc5450c] .title .fields {
    margin-bottom: 0 !important;
}
.email-template-form[data-v-2bc5450c] .title .fields .field {
      padding-right: 0.75em !important;
}
.email-template-form[data-v-2bc5450c] .title .fields .field:last-of-type {
      padding-right: 0 !important;
}
