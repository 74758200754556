.notFound[data-v-b9bcd515] {
  margin-top: 4em;
  text-align: center !important;
  font-size: 1.3rem;
}
.notFound h1[data-v-b9bcd515] {
    font-size: 3.5rem;
}
.notFound h3[data-v-b9bcd515] {
    font-size: 1.4rem;
}
