.type[data-v-9ac36873] {
  float: left;
  text-align: left;
  width: 25%;
}
.number[data-v-9ac36873] {
  text-align: center;
  width: 60%;
}
.is-enabled[data-v-9ac36873] {
  float: right;
  width: 25%;
}
