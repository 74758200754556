.taskDetails > .segment[data-v-41cc2c55] {
  margin: 2em !important;
  padding: 1.2em 1.5em 1.5em 1.5em !important;
}
.taskDetails > .segment > .container[data-v-41cc2c55] {
    padding-top: 0;
    margin-bottom: 1em;
}
.taskDetails .headerContainer[data-v-41cc2c55] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.taskDetails .headerContainer .header[data-v-41cc2c55] {
    margin: 0.3em 0 !important;
}
.taskDetails .headerContainer .header .icon[data-v-41cc2c55] {
      font-size: 1em;
}
.taskDetails .taskInfoContainer[data-v-41cc2c55] {
  margin: 1.5em 0;
}
.taskDetails[data-v-41cc2c55] .ui.tab {
  background-color: #f0f0f0;
  padding: 1em;
}
