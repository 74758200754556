.color-picker[data-v-ddff3008]  {
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #cecece;
}
.color-picker[data-v-ddff3008] .inner-color {
    border-radius: 2px;
}
.color-picker[data-v-ddff3008] .clickable:hover {
    cursor: pointer;
}
