.validation-options[data-v-33d18099]  {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.validation-options[data-v-33d18099] .default-options {
    display: flex;
}
.validation-options[data-v-33d18099] .default-options p {
      font-weight: 600;
      margin-left: 0.5em;
}
.validation-options[data-v-33d18099] .option-cell {
    display: flex;
}
.validation-options[data-v-33d18099] .option-cell p {
      margin-left: 0.5em;
}
