.sequence.campaigns.list .item[data-v-95f71236] {
  display: inline-block;
}
.sequence.campaigns.list .item[data-v-95f71236]:after {
  display: inline-block;
  visibility: visible;
  margin-right: 0.5em;
  content: ", ";
}
.sequence.campaigns.list .item[data-v-95f71236]:last-child:after {
  content: "";
}
