.sequence-step-form[data-v-763a1181] .step-conditions .field:first-child .ui.search.selection.dropdown {
  min-width: 230px !important;
}
.sequence-step-form[data-v-763a1181] .step-conditions .field:last-child > label {
  min-width: 80px !important;
}
.sequence-step-form[data-v-763a1181] .step-conditions .status-select label {
  min-width: 80px !important;
  margin-left: 10px !important;
}
.sequence-step-form[data-v-763a1181] .step-conditions .status-select .ui.selection.dropdown {
  min-width: 180px !important;
}
.sequence-step-form[data-v-763a1181] .time-unit .ui.selection.dropdown {
  min-width: 150px !important;
}
