.contacts-labels[data-v-465fd2d6]  {
  display: flex;
  justify-content: space-between;
}
.contacts-labels[data-v-465fd2d6] .icon {
    margin: 0 0 0 0.5em !important;
}
.contacts-inline[data-v-465fd2d6] {
  display: flex;
}
.contacts-count[data-v-465fd2d6] {
  padding: 0 7px;
}
.campaign-contacts[data-v-465fd2d6]  {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  margin-bottom: 1em;
}
.campaign-contacts[data-v-465fd2d6] div {
    display: inline-flex;
    align-items: center;
}
.campaign-contacts[data-v-465fd2d6] .ui.divider {
    margin-left: 0.5em;
    margin-bottom: 0;
    width: 100%;
    border-bottom: none;
}
.campaign-contacts[data-v-465fd2d6] .ui.button {
    margin-right: 1em;
}
.campaign-contacts[data-v-465fd2d6] .ui.tiny.white.compact.button {
    padding-left: 0.6em;
    padding-right: 0.6em;
}
.campaign-contacts[data-v-465fd2d6] .ui.button:not(.white),
  .campaign-contacts[data-v-465fd2d6] .ui.button:not(.white):focus {
    color: #555;
}
.campaign-contacts[data-v-465fd2d6] .ui.button:not(.white):hover {
    color: #333;
}
.campaign-contacts[data-v-465fd2d6] .ui.button.light.orange {
    background-color: #f98f25;
}
.campaign-contacts[data-v-465fd2d6] .ui.button.light.orange:hover {
    background-color: #fa9d40;
}
.campaign-contacts[data-v-465fd2d6] .ui.button.light.orange:active {
    background-color: #ea8d30;
}
.campaign-contacts[data-v-465fd2d6] .ui.button.orange {
    background-color: #fb511d;
}
.campaign-contacts[data-v-465fd2d6] i.blue.icon {
    color: #000066 !important;
}
