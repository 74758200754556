.sidebar[data-v-b517cf94]  {
  margin-right: 0;
}
.sidebar[data-v-b517cf94] aside {
    width: 250px;
    margin-right: 0;
    padding-top: 0.5em;
    padding-bottom: 1em;
}
.sidebar[data-v-b517cf94] .ui.menu {
    box-shadow: none;
    border: none;
}
