@charset "UTF-8";
.table-options {
  margin-left: auto;
  flex-grow: 1;
  text-align: right; }

.table-top-buttons {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: none !important;
  min-height: 60px; }

.table-top-buttons:last-child {
  justify-content: flex-end; }

.table-top-buttons > * {
  margin: 1em 0.5em !important; }

.table-options > * {
  margin: 0 0.5em !important; }

.table-merge-items {
  margin-bottom: 1em;
  font-weight: bold; }

.table-top-buttons > *:first-child {
  margin-left: 1em !important; }

.table-top-buttons > .ui.mini.button,
.table-options > .ui.mini.button {
  background: white;
  border: 2px solid #f0f0f0;
  padding: 8px !important;
  color: #878787; }

.filter-buttons > .ui.mini.button {
  background: white;
  border: 2px solid #f0f0f0;
  padding: 8px !important;
  color: #878787;
  margin-bottom: 0.5em !important; }

.rt-table {
  font-size: 12px; }

.rt-th {
  border: none !important;
  background: #f6f6f6 !important;
  font-weight: bold !important; }

.rt-thead.-header {
  box-shadow: none !important; }

.rt-td a {
  color: black;
  font-weight: bold; }

.rt-td .ui.horizontal.list > .item {
  font-size: 12px; }

.rt-tr-group {
  border-bottom: 1px solid #eee !important; }

.centered {
  text-align: center; }

.action-menu {
  text-align: center;
  overflow: unset !important; }

.action-menu .action-button {
  display: block !important; }

.rt-tr {
  align-items: center;
  margin: 0.5em 0; }

.rt-thead.-header .rt-tr {
  margin: 0; }

.action-button {
  display: block;
  background: white !important;
  border: none !important; }

.rt-th {
  cursor: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==), auto !important; }

.-sort-desc {
  box-shadow: none !important;
  cursor: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIRnC2nKLnT4or00Puy3rx7VQAAOw==), auto !important; }
  .-sort-desc:before {
    content: "▼";
    float: right; }

.-sort-asc {
  box-shadow: none !important;
  cursor: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=), auto !important; }
  .-sort-asc:before {
    content: "▲";
    float: right; }

.rt-draggable-container .draggable-header.enable-drag:hover {
  cursor: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==), auto !important; }

.rt-draggable-container .draggable-header.enable-drag:active {
  cursor: move !important; }

.rt-draggable-container .non-sortable:hover,
.rt-draggable-container .non-sortable .draggable-header:hover {
  cursor: auto !important; }

.rt-draggable-container .non-sortable:active,
.rt-draggable-container .non-sortable .draggable-header:active {
  cursor: move !important; }

table.include-contacts td {
  overflow-wrap: anywhere;
  white-space: pre-wrap; }

.manage-tags-table {
  display: block;
  height: 250px;
  overflow: auto; }

.DataJobErrors.ReactTable .rt-td:last-child {
  text-overflow: unset;
  word-break: break-all;
  white-space: break-spaces; }

.campaign-segment > .ui.segment,
.ui.divided.grid.bg.list-grid {
  border: #d8d8d8 1px solid;
  border-radius: 5px; }

.ui.fluid.container.admin.route > .ui.segment {
  margin: 1.5em !important;
  padding: 1em; }
