.advanced-search.modal[data-v-508506fd] .load-search-container {
  text-align: right;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.advanced-search.modal[data-v-508506fd] .load-search-container .menu {
    position: absolute;
    right: 0;
}
.advanced-search.modal[data-v-508506fd] .load-search-container .menu .item {
      display: flex;
      padding: 0.75em 0.25em 0.75em 0.75em !important;
}
.advanced-search.modal[data-v-508506fd] .load-search-container .menu .first {
      flex: 1 1;
      min-width: 0;
}
.advanced-search.modal[data-v-508506fd] .load-search-container .menu .second {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
}
.advanced-search.modal[data-v-508506fd] .load-search-container .menu .description {
      float: none;
      margin: 0;
      color: rgba(0, 0, 0, 0.4);
      font-size: 0.85em;
}
.advanced-search.modal[data-v-508506fd] .load-search-container .menu .close.icon {
      margin: 0;
}
.advanced-search.modal[data-v-508506fd] .ui.form .inline.field .ui.input,
.advanced-search.modal[data-v-508506fd] .ui.selection {
  min-width: 180px !important;
  max-width: none;
  width: 100% !important;
}
.advanced-search.modal[data-v-508506fd] .ui.grid > .row {
  margin-bottom: 1em;
}
.advanced-search.modal[data-v-508506fd] .range-filter > .field {
  margin-bottom: 0;
}
.advanced-search.modal[data-v-508506fd] .range-filter .ui.selection {
  width: 220px !important;
}
.advanced-search.modal[data-v-508506fd] .range-filter .ui.input {
  width: 80px !important;
}
.advanced-search.modal[data-v-508506fd] .range-filter .range-start {
  margin-right: 0;
}
.advanced-search.modal[data-v-508506fd] .range-filter .and-label {
  margin: 0 0.5em;
}
.advanced-search.modal[data-v-508506fd] .and-or-selector {
  margin-top: -1em;
  margin-bottom: 1em;
}
.advanced-search.modal[data-v-508506fd] .and-or-selector span.text-link {
    cursor: pointer;
}
.advanced-search.modal[data-v-508506fd] .and-or-selector span.selected {
    font-weight: bold;
}
