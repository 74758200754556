.ReactTable.select-column .rt-thead .rt-th[data-v-8a5b3831]:first-child {
  flex: 0 1 40px;
}
.ReactTable.select-column .rt-table .rt-td[data-v-8a5b3831]:first-child {
  flex: 0 1 40px;
}
.ReactTable .table-container[data-v-8a5b3831] {
  height: 100%;
}
.ReactTable .table-toolbar[data-v-8a5b3831] {
  height: 50px;
  padding: 0 0.5em;
  margin-bottom: -5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ReactTable .table-inner[data-v-8a5b3831] {
  height: calc(100% - 50px);
}
.ReactTable .rt-thead .rt-th[data-v-8a5b3831] {
  display: inline-block;
  position: relative;
}
.ReactTable .rt-thead .rt-th.toggle[data-v-8a5b3831] {
  flex: 0 1 150px;
}
.ReactTable .rt-tbody[data-v-8a5b3831] {
  overflow-x: hidden;
  overflow-y: auto;
}
.ReactTable .rt-table .rt-tr .rt-td[data-v-8a5b3831] {
  min-height: 2.5em !important;
  flex: 100 0 auto;
  width: 100px;
}
.ReactTable .rt-table .rt-tr .rt-td.toggle[data-v-8a5b3831] {
    flex: 0 1 150px;
    text-align: center;
}
.ReactTable .resizer[data-v-8a5b3831] {
  display: inline-block;
  width: 20px;
  background: #f6f6f6;
  height: 27px;
  position: relative;
  right: 0;
  top: 0;
  z-index: 1;
  touch-action: none;
}
[data-v-8a5b3831] .table-top {
  padding: 1em;
}
[data-v-8a5b3831] .table-top .left,[data-v-8a5b3831] .table-top .right {
    display: inline-block;
}
[data-v-8a5b3831] .table-top .left .ui.dropdown .menu:not(.visible),[data-v-8a5b3831] .table-top .right .ui.dropdown .menu:not(.visible) {
      display: none !important;
}
[data-v-8a5b3831] .table-top .right {
    float: right;
}
[data-v-8a5b3831] .table-top .right .ui.mini.button {
      background: white;
      border: 2px solid #f0f0f0;
      padding: 8px !important;
      color: #878787;
      font-weight: bold;
}
[data-v-8a5b3831] .table-top .create.button {
    display: inline-block;
    margin-right: 1em;
}
[data-v-8a5b3831] .table-top .create.button button {
      margin-right: 0;
}
[data-v-8a5b3831] .table-top .search.input {
    margin-right: 1em;
}
[data-v-8a5b3831] .table-top button.advanced.search {
    background: none;
    border: 2px solid #f0f0f0;
    margin-right: 1em;
}
[data-v-8a5b3831] .table-top .filter-buttons {
    margin-top: 10px;
}
[data-v-8a5b3831] .table-top .filter-buttons button:first-child {
      margin-left: 0 !important;
}
