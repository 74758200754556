.tasks.sidebar[data-v-b25b1159] .basic.segment {
  margin: 0;
  padding: 1em 0;
  padding-right: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tasks.sidebar[data-v-b25b1159] .basic.segment.top {
    padding-top: 2em !important;
}
.tasks.sidebar[data-v-b25b1159] .basic.segment.vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tasks.sidebar[data-v-b25b1159] .basic.segment .textLink {
    margin: 4px 0;
    padding: 2px 0 !important;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    text-align: left;
}
.tasks.sidebar[data-v-b25b1159] .basic.segment .selected {
    font-weight: bold;
}
