.draggable-container.draggable-container > div > *[data-v-0783492b] {
  border: 1px solid #f0f0f0 !important !important;
  border-radius: 5px;
  border-left: 0.5em solid #f0f0f0;
  min-height: 1em;
  margin-bottom: 5px;
  padding: 0.5em 1em 0.5em 1em;
  margin: 0 10px 10px 0;
}
.draggable-container.draggable-container > div > *[data-v-0783492b]:hover, .draggable-container.draggable-container > div > *[data-v-0783492b]:active {
    /*border: 1px solid #f0f0f0; */
    border: 1px solid black !important !important;
    background-color: #f0f0f0;
    border-left: 0.5em solid #0042b0;
}
.draggable-container.draggable-container > div.horizontal[data-v-0783492b] {
  display: inline-block;
}
