.stepInfo > .header[data-v-86d55578] {
  margin: 0.5em !important;
}
.stepInfo .stepName.header[data-v-86d55578] {
  font-size: 18px !important;
  margin-top: 1em !important;
}
.stepInfo .trigger.header[data-v-86d55578] {
  font-size: 14px !important;
  margin-bottom: 1em !important;
}
