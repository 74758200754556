.stepInfo.card[data-v-f4142822]  {
  position: relative;
}
.stepInfo.card[data-v-f4142822] > a {
    color: inherit;
}
.stepInfo.card[data-v-f4142822] .content {
    padding: 1em;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.stepInfo.card[data-v-f4142822] .content .ui.grid {
      flex: 1 1;
}
.stepInfo.card[data-v-f4142822] .main.column .header {
    display: inline-block;
    margin-right: 10px;
    max-width: 500px;
    overflow: visible;
    text-overflow: ellipsis;
}
.stepInfo.card[data-v-f4142822] .main.column .targetRow span {
    margin-right: 1em;
}
.stepInfo.card[data-v-f4142822] .main.column .secondRow {
    margin-top: 5px;
}
.stepInfo.card[data-v-f4142822] .main.column .secondRow .priority.label {
      margin-right: 1.5em;
}
.stepInfo.card[data-v-f4142822] .main.column .secondRow .assignees {
      font-weight: bold;
}
.stepInfo.card[data-v-f4142822] .main.column .secondRow .assignees .icon {
        padding: 0.5em;
        margin-right: 0.7em;
        width: 2em;
        height: 2em;
        font-size: 0.7em;
        vertical-align: middle;
}
.stepInfo.card[data-v-f4142822] .right.column {
    padding-left: 1em !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    display: flex !important;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}
.stepInfo.card[data-v-f4142822] .right.column .status {
      font-size: 1.1em;
}
.stepInfo.card[data-v-f4142822] .right.column .dueDate.label {
      color: #666;
      background: none !important;
      font-weight: normal;
      font-size: 1em;
      margin-top: 1em;
      padding: 0.4em 0.5em;
}
.stepInfo.card[data-v-f4142822] .right.column .dueDate.label.red {
        background-color: #f88177 !important;
        color: #fff !important;
}
.stepInfo.card[data-v-f4142822] .right.column .dueDate.label.yellow {
        background-color: #fdee6e !important;
        color: #666 !important;
}
.stepInfo.card[data-v-f4142822] .delete {
    position: absolute;
    top: 0;
    right: 25px;
    width: 40px;
    height: 40px;
    padding: 1em;
    padding-left: 0.5em;
}
.stepInfo.card[data-v-f4142822] .edit {
    position: absolute;
    top: 0;
    right: -5px;
    width: 40px;
    height: 40px;
    padding: 1em;
    padding-left: 0.5em;
}
