.dialer-ui[data-v-6e456f21] {
  display: flex;
  height: 100%;
}
.dialer-ui .main[data-v-6e456f21] {
    width: 100%;
    padding: 1.5em 2.5em 4em 0;
}
.dialer-ui .main .ui.navbar.menu[data-v-6e456f21] {
      margin-top: 0.5em;
}
.dialer-ui .main .dialer-top[data-v-6e456f21] {
      display: flex;
}
.dialer-ui .main .dialer-top .dialer-numbers[data-v-6e456f21] {
        display: flex;
}
.dialer-ui .main .dialer-top .dialer-numbers .required-consent[data-v-6e456f21] {
          display: flex;
          flex-direction: column;
          margin-left: 1em;
          margin-top: 25px;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number[data-v-6e456f21] {
          display: flex;
          flex-direction: column;
          margin-left: 1em;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number p[data-v-6e456f21] {
            padding: 0;
            margin-bottom: 5px;
            font-weight: bold;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number .ui.dial.button > .ui.button[data-v-6e456f21] {
            font-size: 1em;
            color: #fff;
            background-color: #000066;
            padding: 0.5em 1em;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number .ui.dial.button:hover > .ui.label[data-v-6e456f21] {
            color: #1e70bf;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number .ui.dial.button:hover > .ui.button[data-v-6e456f21] {
            background-color: #000044;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number .dialer-number-selector[data-v-6e456f21] {
            padding: 0.6em 0.8em;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-right: none;
            border-radius: 3px 0 0px 3px;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number .dialer-number-selector .times-called[data-v-6e456f21] {
              color: black;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number .ui.recording.buttons .button[data-v-6e456f21] {
            font-size: 1rem;
            background-color: white !important;
            padding: 0.6em 1em;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number .ui.recording.buttons .button[data-v-6e456f21]:hover {
              background-color: rgba(0, 0, 0, 0.03) !important;
}
.dialer-ui .main .dialer-top .dialer-numbers .dialer-number[data-v-6e456f21]:first-child {
          margin-left: 0;
}
.dialer-ui .main .dialer-top .dialer-location[data-v-6e456f21] {
        margin-top: 0.8em;
}
.dialer-ui .main .dialer-top .dialer-location span[data-v-6e456f21] {
          font-size: 0.8rem;
          opacity: 0.8;
}
@-webkit-keyframes blinker-data-v-6e456f21 {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}
@keyframes blinker-data-v-6e456f21 {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}
.icon.blink[data-v-6e456f21] {
  text-decoration: blink;
  -webkit-animation-name: blinker-data-v-6e456f21;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}
.button.blink[data-v-6e456f21] {
  text-decoration: blink;
  -webkit-animation-name: blinker-data-v-6e456f21;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}
